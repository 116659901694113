import './App.css';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import Home from './components/Home';
// import AboutUs from './components/AboutUs';
import Resume from './components/Resume';
import Layout from './components/Layout';

function NavLink({ to, neutralSrc, hoverSrc, hoverActiveSrc, activeSrc, alt }) {
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <Link to={to}>
      <img
        src={isActive ? activeSrc : neutralSrc}
        alt={alt}
        className={`nav-image ${isActive ? 'nav-image-active' : ''}`}
        onMouseEnter={(e) => (e.currentTarget.src = isActive ? hoverActiveSrc : hoverSrc)}
        onMouseLeave={(e) => (e.currentTarget.src = isActive ? activeSrc : neutralSrc)}
      />
    </Link>
  );
}

function App() {
  const dndUrl = process.env.REACT_APP_DND_URL;
  return (
    <Router>
      <nav>
        <a href="/"><img src="images/CL_Bk_ICO.png" alt="Clean LAN" className="logo" /></a>
        <NavLink
          to="/"
          neutralSrc="/images/Home.gif"
          hoverSrc="/images/Home_x.gif"
          hoverActiveSrc="/images/Home_x.png"
          activeSrc="/images/Home.png"
          alt="Home"
        />
        {/* <NavLink
          to="/AboutUs"
          neutralSrc="/images/About_Us.gif"
          hoverSrc="/images/About_Us_x.gif"
          hoverActiveSrc="/images/About_Us_x.png"
          activeSrc="/images/About_Us.png"
          alt="About Us"
        /> */}
        <NavLink
          to="/Resume"
          neutralSrc="/images/Resume.gif"
          hoverSrc="/images/Resume_x.gif"
          hoverActiveSrc="/images/Resume_x.png"
          activeSrc="/images/Resume.png"
          alt="Resume"
        />
        <NavLink
          to="/dnd"
          neutralSrc="/images/DnD.gif"
          hoverSrc="/images/DnD_x.gif"
          hoverActiveSrc="/images/DnD_x.png"
          activeSrc="/images/DnD.png"
          alt="DnD"
        />
      </nav>
      <div className='container'>
        <Routes>
          <Route path='/' element={<Layout><Home /></Layout>}></Route>
          {/* <Route path="/AboutUs" element={<Layout><AboutUs /></Layout>} /> */}
          <Route path="/Resume" element={<Layout><Resume /></Layout>} />
          <Route path='/dnd' element={<iframe title='dnd' src={dndUrl} style={{ width: '100%', height: 'calc(100vh - 60px)', border: 'none' }} />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
