import React from "react";
import { Link } from "react-router-dom";
import "./Home.css";

export default function Home() {
    return (
        <main className="home-container">
            <header className="home-header">
                <h1>Welcome to Clean LAN</h1>
            </header>
            <section className="home-content">
                <article className="home-intro">
                    <h2>About Clean LAN</h2>
                    <p>
                        Welcome to Clean LAN! This site, originally created during the golden age of LAN parties, has evolved to reflect my interests. While I am still interested in LAN parties, with the advent of high-speed internet 
                        and the ease of online services, it has transformed how we connect and play. My own interests have shifted this website away from LAN gaming and onto other pursuits. Now this site is used as my testing ground for my personal projects.
                    </p>
                </article>

                <article className="home-dnd">
                    <h2>
                        <Link to="/dnd" title="Go to the Dungeons and Dragons Campaign page">Dungeons and Dragons Campaign</Link>
                    </h2>
                    <p>
                        This includes a Dungeons and Dragons Campaign that I am hosting with friends of mine. This is a unique twist on the normal game in that everything from the classes to skills are created during gameplay and 
                        reflective of what the player was doing at the time. This was done using AI prompting to give examples or ideas that I then used to create the content.
                    </p>
                    <p>
                        Here you can look at the current status of the DnD players. This site was designed to help them with managing their characters and deciding what skills or spells to level as they play and earn experience. 
                        The game's data is hosted here on this site and managed through a campaign manager I have created. As this is a currently ongoing campaign, the manager is not public-facing and has securities to prevent tampering with the characters.
                    </p>
                </article>

                <article className="home-resume">
                    <h2>
                        <Link to="/resume" title="View my redacted resume">Redacted Resume</Link>
                    </h2>
                    <p>
                        I have a redacted form of my resume here. I have it redacted so that I don't get unsolicited emails or calls. If you wish to reach out to me for a job offer or opportunity, please do so via the appropriate avenues like 
                        <a href="https://www.linkedin.com/in/andrew-hales-003b63185/" target="_blank" rel="noopener noreferrer" title="Visit my LinkedIn profile">LinkedIn</a>.
                    </p>
                </article>
            </section>
            <footer className="home-footer">
                <p>Thank you for visiting Clean LAN! I hope you enjoy exploring the site and discovering the various facets of my work and interests.</p>
            </footer>
        </main>
    );
}
