import React from 'react';

function Resume() {
  return (
    <div className="resume-container">
      <iframe
        src="/documents/resume.pdf"
        title="Resume"
        style={{ width: '100%', height: '100vh', border: 'none' }}
      >
        This browser does not support PDFs. Please download the PDF to view it: <a href="/documents/resume.pdf">Download PDF</a>.
      </iframe>
    </div>
  );
}

export default Resume;
