import React from 'react';
import { useLocation } from 'react-router-dom';
import Footer from './Footer';

function Layout({ children }) {
  const location = useLocation();
  const showFooter = !location.pathname.startsWith('/dnd');

  return (
    <>
      <div className="content">{children}</div>
      {showFooter && <Footer />}
    </>
  );
}

export default Layout;
