import React from "react";
import '../App.css';

export default function Footer() {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="footer">
            <p className="footer-text">
                © {currentYear} CleanLAN.net, All Rights Reserved
            </p>
        </footer>
    )
}